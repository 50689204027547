import "./Delta.scss";

import React, { useState } from "react";

const cardInfo = [
    {id: "1", name: "Team Sponsorships!", message:"We currently sponsor two platers on a local Lacrosse team. These resources go towards helping fund the team as they continue to grow and make us proud!", img:"/imgs/teams.png"},
    {id: "2", name: "Kid Sport ", message:"We are a proud contributor to the KidSport Foundation. We believe that every child deserves an opportunity to participate. If you’d like to contribute... visit: http://www.kidsportcanada.ca/", img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyiJ41I2OVRq0RZwvQvNdsU-AWJn-8cKfVI6hOZkoJg&s"},
    {id: "3", name: "Community Parade", message:"Ivy League Painting has been an active participant in the Ladner Maydays parade since 2017", img:"https://images-ext-1.discordapp.net/external/SL6x2SDE6_qa6tY97alnHmRe96PCEvCeGd2A2Fu6uYM/https/static.wixstatic.com/media/520d3c_ae8aaf678ac948fe8fb3ee3daaee935a~mv2.jpg/v1/fill/w_276%2Ch_356%2Cal_tl%2Cq_80%2Cusm_0.66_1.00_0.01%2Cenc_auto/520d3c_ae8aaf678ac948fe8fb3ee3daaee935a~mv2.jpg?format=webp"},
]

export function Delta() {
    const [selectedCard, setSelectedCard] = useState(cardInfo[0].img);
    
    const handleCardClick = (img: string) => {
        setSelectedCard(img);
    }


    return (
        <div id="delta" className="delta white">
            <div className='titleText'>We Love Delta </div>
            <div className='medium ptb-3'>We don’t only strive to keep your property in top shape, but our entire community as well! We are operated and founded locally, so we make sure to leave our mark!</div>
            
            <div className="center mobile">
                <img className='br-25' src={selectedCard} alt="team" />
            </div>

            <div className='row end2end'>
                <div className="column center ">
                    {cardInfo.map((card) => {
                        return (
                            <div key={card.id} className="card w75" onClick={() => handleCardClick(card.img)}>
                                <div className='mobileText titleText pt-1'>{card.name}</div>
                                <div className='medMobileText medium ptb-1'>{card.message}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="center halfW desk">
                    <img className='br-25' src={selectedCard} alt="team" />
                </div>
            </div>
        </div>
    );
    }