import React, { useEffect } from 'react';
import { Header } from './components/Header';
import { Home } from './pages/Home';
import { Services } from './pages/Services';
import { About } from './pages/About';
import { Work } from './pages/Work';
import { Reviews } from './pages/Reviews';
import { Delta } from './pages/Delta';
import { Contact } from './pages/Contact';
import { Footer } from './components/Footer';

const App: React.FC = () => {
  useEffect(() => {
    const path = window.location.pathname.replace('/', '');
    if (path) {
      const section = document.getElementById(path);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const links = [
    { id: 'services', text: 'Services' },
    { id: 'about', text: 'About' },
    { id: 'reviews', text: 'Reviews' },
    { id: 'delta', text: 'Community' },
    { id: 'contact', text: 'Contact' },
  ]

  return (
    <>
      <Header links={links}/>
      <Home />
      <Services />
      <About />
      <Work />
      <Reviews />
      <Delta />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
