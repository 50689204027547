import React from 'react';
import './Services.scss'
import interior from '../assests/interior.jpeg'
import exterior from '../assests/exteriors.jpeg'
import stain from '../assests/staining.jpeg'
import cabs from '../assests/cabs.jpeg'
export function Services() {

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const cardInfo = [
        {title: "Interiors", text: "Transform your indoor spaces with our expert interior painting services, tailored to match your unique style and decor.", imgSrc: `${interior}`},
        {title: "Exteriors", text: "Revitalize your home's exterior with our durable and high-quality exterior painting solutions, designed to withstand the elements.", imgSrc: `${exterior}`},
        {title: "Kitchen Cabinets", text: "Elevate your kitchen's look with our specialized cabinet painting services, bringing a fresh and modern feel to your cooking area.", imgSrc: `${cabs}`},
        {title: "Staining", text: "Enhance the natural beauty of your wood surfaces with our professional staining services, perfect for decks, fences, and furniture.", imgSrc: `${stain}`},
    ];
    
    return (
        <div id="services" className="services"> 
           <div className="titleText white"> How we Help </div>
           <div className="medium white ptb-3">We offer a range of services, all aimed at taking your project to the next level! Choose one of our services below to get in touch with our mobile appraiser: </div>
           <div className='desk row center p-1'>
                {cardInfo.map((card, index) => (
                    <div key={index} className='card column cardBlue'>
                        <img src={card.imgSrc} alt='service' className='service' />
                        <div className='medium left white pt-1'>{card.title}</div>
                        <div className='small ptb-1 fadedWhiteText'>{card.text}</div>
                        <button className='button fadedWhite white' onClick={() => scrollToSection('contact')}>book now</button>
                    </div>
                ))}
           </div>
           <div className='mobile row middle p-1'>
           {cardInfo.map((card, index) => {
            if (index === 0 || index === 1) {
                return (
                    <div key={index} className='card column cardBlue'>
                        <img src={card.imgSrc} alt='service' className='service' />
                        <div className='medium left white pt-1'>{card.title}</div>
                        <div className='small ptb-1 fadedWhiteText'>{card.text}</div>
                        <button className='button fadedWhite white' onClick={() => scrollToSection('contact')}>book now</button>
                    </div>
                );
            } else {
                return null;
            }
        })}
           </div>
           <div className='mobile row middle p-1'>
           {cardInfo.map((card, index) => {
            if (index === 2 || index === 3) {
                return (
                    <div key={index} className='card column cardBlue'>
                        <img src={card.imgSrc} alt='service' className='service' />
                        <div className='medium left white pt-1'>{card.title}</div>
                        <div className='small ptb-1 fadedWhiteText'>{card.text}</div>
                        <button className='button fadedWhite white'  onClick={() => scrollToSection('contact')}>book now</button>
                    </div>
                );
            } else {
                return null;
            }})}
           </div>
        </div>
    );
}