import React, { useState, useEffect } from "react";
import "./Work.scss";
import p0 from '../assests/0.jpeg';
import p1 from '../assests/1.jpeg';
import p2 from '../assests/2.jpeg';
import p3 from '../assests/3.jpeg';
import p4 from '../assests/4.jpeg';
import p5 from '../assests/5.jpeg';
import p6 from '../assests/6.jpeg';
import p7 from '../assests/7.jpeg';
import p8 from '../assests/8.jpeg';
import p9 from '../assests/9.jpeg';
import p10 from '../assests/10.jpeg';
import p11 from '../assests/11.jpeg';
import p12 from '../assests/12.jpeg';
import p13 from '../assests/13.jpeg';
import p14 from '../assests/14.jpeg';
import p15 from '../assests/15.jpeg';
import p16 from '../assests/16.jpeg';
import p17 from '../assests/17.jpeg';
import p18 from '../assests/18.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Swiper as SwiperClass } from 'swiper';

const imgs: Record<ImageKey, string[]> = {
    d1: [p1,p2,p3],
    d2: [p4,p5,p6,p7],
    d3: [p8,p9,p10],
    d4: [p11,p12,p13],
    d5: [p14,p15,p16,p17],
    d6: [p18,p1,p2],
    m1: [p1,p2,p3],
    m2: [p4,p5,p6,p7],
    m3: [p8,p9,p10],
    m4: [p11,p12,p13],
};

type ImageKey = 'd1' | 'd2' | 'd3' | 'd4' | 'd5' | 'd6' | 'm1' | 'm2' | 'm3' | 'm4';

export function Work() {
    const [showGallery, setShowGallery] = useState(false);
    const [galleryImg, setGalleryImg] = useState(1);

    const totalImages = 58;

    const getImagePath = (index: number): string => {
        const formattedIndex = index.toString().padStart(3, '0');
        return `/imgs/${formattedIndex}.jpeg`;
    };

    const goToNextImage = () => {
        setGalleryImg((prevIndex) =>
            prevIndex < totalImages ? prevIndex + 1 : 1
        );
    };

    const goToPreviousImage = () => {
        setGalleryImg((prevIndex) =>
            prevIndex > 1 ? prevIndex - 1 : totalImages
        );
    };

    
    const [opacity, setOpacity] = useState<Record<ImageKey, number>>({
        d1: 1, d2: 1, d3: 1, d4: 1, d5: 1, d6: 1, m1: 1, m2: 1, m3: 1, m4: 1
    });

    const [currentImageIndex, setCurrentImageIndex] = useState({
        d1: 0, d2: 0, d3: 0, d4: 0, d5: 0, d6: 0, m1: 0, m2: 0, m3: 0, m4: 0,
    });

    useEffect(() => {
        const updateImage = (key: ImageKey, interval: number) => {
            const timer = setInterval(() => {
                setOpacity(prev => ({ ...prev, [key]: 0 })); 
                setTimeout(() => {
                    setCurrentImageIndex(prevState => ({
                        ...prevState,
                        [key]: (prevState[key] + 1) % imgs[key].length
                    }));
                    setOpacity(prev => ({ ...prev, [key]: 1 }));
                }, 2000); 
            }, interval);
            return () => clearInterval(timer);
        };

        const timers = {
            d1: updateImage('d1', 10000), 
            d2: updateImage('d2', 9000), 
            d3: updateImage('d3', 7000),
            d4: updateImage('d4', 8500), 
            d5: updateImage('d5', 7500), 
            d6: updateImage('d6', 9500), 
            m1: updateImage('m1', 10000), 
            m2: updateImage('m2', 9000),
            m3: updateImage('m3', 7000), 
            m4: updateImage('m4', 8000),
        };

        return () => {
            Object.values(timers).forEach(clear => clear());
        };
    }, []);

    const toggleGallery = () => setShowGallery(!showGallery);

    const handleStopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div id="work" className="work"> 
            <div className="desk grid-container cardBlue ">
                <div className="item1">
                    <img style={{ opacity: opacity.d1 }} src={imgs.d1[currentImageIndex.d1]} alt="team" />
                </div>
                <div className="item2">
                    <img style={{ opacity: opacity.d2 }} src={imgs.d2[currentImageIndex.d2]} alt="team" />
                </div>
                <div className="item3">
                    <img style={{ opacity: opacity.d3 }} src={imgs.d3[currentImageIndex.d3]} alt="team" />
                </div>
                <div className="item4 row" >
                    <img style={{ opacity: opacity.d4 }} src={imgs.d4[currentImageIndex.d4]} alt="team" />
                    <img style={{ opacity: opacity.d5 }} src={imgs.d5[currentImageIndex.d5]} alt="team" />
                </div>
                <div className="item5 middle white">
                    <div className='titleText p-1'>What We Do</div>
                    <div className='medium-small p-1'>At the heart of our painting business lies a simple promise: to bring new life to your spaces with unparalleled precision and beauty. We blend traditional techniques with modern innovation to ensure every brushstroke adds value and vitality. Our commitment to excellence shines through in every color choice and stroke, transforming homes and businesses into works of art.</div>
                </div>
                <div className="item6">
                    <img style={{ opacity: opacity.d6 }} src={imgs.d6[currentImageIndex.d6]} alt="team" />
                </div>
            </div>

            <div className="mobile column cardBlue ">
                <div className="item10">
                    <img style={{ opacity: opacity.m1 }} src={imgs.m1[currentImageIndex.m1]} alt="team" />
                </div>
                <div className="item11 row">
                    <img style={{ opacity: opacity.m2 }} src={imgs.m2[currentImageIndex.m2]} alt="team" />
                    <img style={{ opacity: opacity.m3 }} src={imgs.m3[currentImageIndex.m3]} alt="team" />
                </div>
                <div className="item12">
                    <img style={{ opacity: opacity.m4 }} src={imgs.m4[currentImageIndex.m4]} alt="team" />
                </div>
                <div className="item13 middle white">
                    <div className='titleText p-1'>What We Do</div>
                    <div className='medium-small p-1'>At the heart of our painting business lies a simple promise: to bring new life to your spaces with unparalleled precision and beauty. We blend traditional techniques with modern innovation to ensure every brushstroke adds value and vitality. Our commitment to excellence shines through in every color choice and stroke, transforming homes and businesses into works of art.</div>
                </div>
            </div>
            <div className='row right p-2'>
                <button className="button white fadedGrey" onClick={toggleGallery}>View Full Gallery</button>
            </div>

            {showGallery && (
                <div className='gallery-overlay' onClick={toggleGallery}>
                    {/* desktop gallery */}
                    <FontAwesomeIcon icon={faAngleLeft} color='white' className='desk nextButton even40 pr-2' onClick={(e) => { e.stopPropagation(); goToPreviousImage(); }}/>
                    <img src={getImagePath(galleryImg)} alt="team" className="desk full-width-image" />
                    <FontAwesomeIcon icon={faAngleRight} color='white'  className='desk nextButton even40 pl-2' onClick={(e) => { e.stopPropagation(); goToNextImage(); }}/>
                    {/* mobile */}
                        <Swiper
                            className='mobile'
                            spaceBetween={10}
                            slidesPerView={1}
                            centeredSlides={true}
                            onSlideChange={({ activeIndex }: { activeIndex: number }) => setGalleryImg(activeIndex)}
                            initialSlide={galleryImg}
                        >
                            {Array.from({ length: 58 }).map((_, index) => (
                                <SwiperSlide key={index}>
                                    <img src={getImagePath(index + 1)} alt="Gallery image" className="full-width-image pl-2" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
            )}
            {/* { showGallery && (
                <div className='gallery-overlay' onClick={toggleGallery}>
                    <FontAwesomeIcon icon={faAngleLeft} color='white' className='nextButton even40 pr-2' onClick={(e) => { e.stopPropagation(); goToPreviousImage(); }}/>
                    <img src={getImagePath(galleryImg)} alt="team" className="full-width-image" />
                    <FontAwesomeIcon icon={faAngleRight} color='white'  className='nextButton even40 pl-2' onClick={(e) => { e.stopPropagation(); goToNextImage(); }}/>
                    <div className='mobileButtons row bottom10'>
                        <FontAwesomeIcon icon={faAngleLeft} color='white' className=' even40 pr-2' onClick={(e) => { e.stopPropagation(); goToPreviousImage(); }}/>
                        <FontAwesomeIcon icon={faAngleRight} color='white'  className=' even40 pl-2' onClick={(e) => { e.stopPropagation(); goToNextImage(); }}/>
                    </div>
                     
                </div>
            )} */}
        </div>
    );
}
