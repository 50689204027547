import './Reviews.scss'
import React from 'react';
import { Slider } from '../components/Slider';
import jewel_richie from '../assests/review_imgs/jewel_ritchie.jpg';
import joe_dardano from '../assests/review_imgs/joe_dardano.jpeg';
import mickey_sangha from '../assests/review_imgs/mickey_sangha.jpg';
import geoff_eccott from '../assests/review_imgs/geof_eccot.jpeg';
import carly_farlow from '../assests/review_imgs/carly_farlow.jpeg';
import shana_hochfelder from '../assests/review_imgs/shana_hochfelder.jpeg';
import connor_barr from '../assests/review_imgs/connor_bar.jpeg';
import noah_langlois_anderson from '../assests/review_imgs/noah_langlois.jpeg';
import julian_li_brubacher from '../assests/review_imgs/julian_li-brubacher.jpeg';
import helen_connolly from '../assests/review_imgs/helen_connolly.jpg';


const cardInfoL = [ 
  {id: "1", text: "Stellar work, amazing detail, meticulous and punctual. Plus the whole team were super sweet. Would recommend them in a heartbeat!", name: "Jewel Ritchie", stars: 5, location: "Surrey, BC", profileIMG: jewel_richie, title:"Stellar"},
  {id: "2", text: "Best service, most reliable and professional painting company in British Columbia. Recommend this company any time. 5 Stars.", name: "Joe Dardano", stars: 5, location: "Surrey, BC", profileIMG: joe_dardano, title:"Professional"},
  {id: "3", text: "did a great job,price was right,", name: "Mickey Sangha", stars: 5, location: "Vancouver, BC", profileIMG: mickey_sangha, title:"Great Job"},
  {id: "4", text: "Jack and his crew just finished painting the exterior of our house. They worked very hard, paid a great deal of attention to detail, cleaned up after themselves and made sure we were completely satisfied.", name: "Geoff Eccott", stars: 5, location: "Vancouver, BC", profileIMG: geoff_eccott, title:"Detail"},
  {id: "5", text: "His professionalism and follow up was amazing. He assured us every step of the way and was an excellent communicator.  We couldn’t be happier, it truly was a huge transformation and we are thrilled!!! Thanks Jack and all the guys at Ivy League.", name: "Carly Farlow ", stars: 5, location: "Vancouver, BC", profileIMG: carly_farlow, title:"thrilled"},
  {id: "6", text: "Ivy League Painting did a fantastic job painting my entire office. They are efficient, tidy and do an excellent job.  Great value I highly recommend Jack and his crew for any interior or exterior painting that needs to be done.", name: "Shana Hochfelder", stars: 5, location: "Delta, BC", profileIMG: shana_hochfelder, title:"Fantastic"},
  {id: "7", text: "Jack and his crew went above and beyond for us. It took me a long time to pick my final paint color, he was super patient and helpful with all my questions and concerns. The guys did an excellent job I would highly recommend them. Thanks again", name: "Connor Barr ", stars: 5, location: "Surrey, BC", profileIMG: connor_barr, title:"Excellent Job"},
  {id: "8", text: "If you need a dependable, responsive, skillful yet still affordable squad call these guys. 100% recommend", name: "Noah Langlois - Anderson ", stars: 5, location: "Surrey, BC", profileIMG: noah_langlois_anderson, title:"Dependable"},
  {id: "9", text: "Was recommended Ivy league painting by a friend. They're very knowledgable and hard working people. I ended up saving a lot of money compared to the other quotes I got.", name: "Julian Li-Brubacher", stars: 5, location: "Surrey, BC", profileIMG: julian_li_brubacher, title:"Knowledgable"},
  {id: "10", text: "Thanks Jack, looks great, crew was on time, worked hard and did a great job", name: "Helen Connolly", stars: 5, location: "Delta, BC", profileIMG: helen_connolly, title:"Great Job"},
]

export function Reviews() {
  
    return (
        <div id="reviews" className="reivews">
          <div className='titleText right white pr-10'> What Our Clients Say </div>
          <div className='medium right white pr-10 ptb-3'>Check out what others have to say about our work!</div>
          {/* <FontAwesomeIcon color='white' className='swipeIcon' icon={faHandPointer} /> */}
          <Slider cardInfoL={cardInfoL}/>
        </div>
    );
}