import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

export function Footer() {
    return (
        <div className="footer middle">
             <div className='row middle flex1 '>
                    <a href="https://www.facebook.com/ivyleaguepaintingltd/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} className='medium' />
                    </a>
                    <a href="https://www.youtube.com/@IvyLeaguePaintingLtd" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} className='medium plr-3' />
                    </a>
                    <a href="https://www.instagram.com/ivyleaguepainting/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className='medium' />
                    </a>
                </div>
            <div className="row fadedWhiteText">
                <div className='medium-small pl-2'>@2024 Ivy League Painting. All Rights Reserved.</div>
                <div className='medium-small row right flex1 pr-2'>Terms & Conditions</div>
            </div>
        </div>
    );
}