import React, { useState } from 'react';
import './Form.scss';

export function Form() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subject, setSubject] = useState('');
    const [address, setAddress] = useState('');
    const [service, setService] = useState('');
    const [date, setDate] = useState('');
    const [booking, setBooking] = useState('');

    const sendEmail = async () => {
        try {
            const response = await fetch('/api/email', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json', 
                },
                body: JSON.stringify({ email, phoneNumber, name, message, subject, address, booking, service, date}),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            
            const data = await response.json();
            console.log('Email sent successfully:', data);
            alert('Email Sent Successfully!');
        } catch (error) {
            console.error('There was a problem sending the email:', error);
            alert('There was a problem sending the email');
        }
    };


    // const sendText = async () => { 
    //     try {
    //         const response = await fetch('/api/text', {
    //             method: 'POST', 
    //             headers: {
    //                 'Content-Type': 'application/json', 
    //             },
    //             body: JSON.stringify({ email, phoneNumber, name, message, subject, address, booking, service, date}),
    //         });

    //         if (!response.ok) throw new Error('Network response was not ok');
            
    //         const data = await response.json();
    //         console.log('Text sent successfully > :', data);
    //         alert('success');
    //     } catch (error) {
    //     console.error('There was a problem sending the text:', error);
    //     alert('There was a problem sending the text');
    //     }
    // }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        console.log('submitting form: ', name, email, phoneNumber, address, subject, message, booking, service, date)
        event.preventDefault();
        await sendEmail();
        // await sendText();
    };

    return (
        <form className='form p-2 br-25 cardBlue' onSubmit={handleSubmit}>
            <div className='label-container'>
                <label className="form-label">Name</label>
            </div>
            <input
                type="text"
                placeholder="Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
            />
            <div className='label-container'>
                <label className="form-label">Email</label>
            </div>
            <input
                type="text"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='label-container'>
                <label className="form-label">Phone Number</label>
            </div>
            <input
                placeholder="Phone Number"
                value={phoneNumber}
                required
                onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <div className='label-container'>
                <label className="form-label">Address</label>
            </div>
            <input
                placeholder="Address"
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
            />
            <div className='label-container'>
                <label className="form-label">Subject</label>
            </div>
             <input
                type="text"
                placeholder="Subject"
                value={subject}
                required
                onChange={(e) => setSubject(e.target.value)}
            />
             <div className='label-container'>
                <label className="form-label">Do you want to request a booking?</label>
            </div>
            <div className='checkbox'>
                <label className="radio-tile">
                    <input type="radio" name="booking" value="yes" checked={booking === 'yes'} onChange={(e) => setBooking(e.target.value)} />
                    <span className="radio-label">Yes</span>
                </label>
                <label className="radio-tile">
                    <input type="radio" name="booking" value="no" checked={booking === 'no'} onChange={(e) => setBooking(e.target.value)} />
                    <span className="radio-label">No</span>
                </label>
            </div>

            { booking === 'yes' && 
                <div className='row ptb-1' >
                <div className='fullWidth'>
                    <div className='label-container'>
                        <label className="form-label">Service</label>
                    </div>
                    <div className="radio-container">
                        <label>
                            <input type="radio" value="interiorPainting" checked={service === 'interiorPainting'} onChange={(e) => setService(e.target.value)} />
                            Interior Painting
                        </label>
                        <label>
                            <input type="radio" value="exteriorPainting" checked={service === 'exteriorPainting'} onChange={(e) => setService(e.target.value)} />
                            Exterior Painting
                        </label>
                        <label>
                            <input type="radio" value="kitchenCabinetPainting" checked={service === 'kitchenCabinetPainting'} onChange={(e) => setService(e.target.value)} />
                            Kitchen Cabinet Painting
                        </label>
                        <label>
                            <input type="radio" value="staining" checked={service === 'staining'} onChange={(e) => setService(e.target.value)} />
                            Staining
                        </label>
                    </div>
                </div>
                <div className='pl-5'>
                    <div className='label-container'>
                        <label className="form-label">Preferred Date</label>
                    </div>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                        style={{ position: 'relative' }} // Ensure position is relative to place the indicator absolutely
                    />
                </div>
                </div>
            }
            <div className='label-container'>
                <label className="form-label">Message</label>
            </div>
            
            <textarea
                placeholder="Message"
                value={message}
                required
                style={{ height: '100px', resize: 'none' }}
                onChange={(e) => setMessage(e.target.value)}
            />

            <div className='button-container'>
                <button type="submit" className='button white fadedGrey'>Send Email</button>
            </div>
        </form>
    );
}
