import './Home.scss'

import React from 'react';

export function Home() {

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id="home"  className="home center">

            <div className='largest textShadow'>Delta's #1 Painting Company</div>

            <div className='medium-large m-1 textShadow'>Award Winning & BBB A+ Accredited Business</div>
            <div className='mt-5'>
            <button className='button min-quote quote-text black quoteButton' onClick={() => scrollToSection('contact')}>Instant Quote</button>
            </div>

            <div className="row p-5 buttonrow">
                <button className='button min-row font-left'>
                    <div className='spaced'>
                    <div className='title button-text-lg black'>
                        200+
                    </div>
                    <div className='sub button-text-sm black'>
                        Happy Customers
                    </div>
                    </div>
                </button>
                <button className='button min-row font-left black'>
                    <div className='spaced'>
                        <div className='title button-text-lg black'>
                        60k+
                    </div>
                    

                    <div className='sub button-text-sm black'>
                        Sq ft Serviced
                    </div></div></button>

                <button className='button min-row font-left'><div className='spaced'><div className='title button-text-lg black'>
                        10+
                    </div>
                    <div className='sub button-text-sm black'>
                        Years Of Experience
                    </div></div></button>
            </div>

            <div className='footer'>
                <a href="https://www.bbb.org/ca/bc/delta/profile/painting-contractors/ivy-league-painting-ltd-0037-1372336/#sealclick" target="_blank" rel="nofollow"><img src="/imgs/bbbseal.png"  alt="Ivy League Painting Ltd. BBB Business Review" /></a>
            </div>
        </div>
    );
}