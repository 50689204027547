import React, { useState } from 'react';
import './Slider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Swiper as SwiperClass } from 'swiper';

type CardInfoL = {
    id: string;
    text: string;
    name: string;
    stars: number;
    location: string;
    profileIMG: string;
    title: string;
};


type SliderProps = {
  cardInfoL: CardInfoL[];
};

export const Slider: React.FC<SliderProps> = ({ cardInfoL }) => {
  const duplicationFactor = 8; 
  const slidingCardsL = Array(duplicationFactor).fill(cardInfoL).flat();
  const [currentIndex, setCurrentIndex] = useState(10);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + slidingCardsL.length) % slidingCardsL.length);
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % slidingCardsL.length);
  };

 

return (
  <div className="slider-container">
      <div className="slider" style={{ transform: `translateX(-${currentIndex * 50}vw)`, transition: 'transform 0.5s ease-in-out' }}>
      {slidingCardsL.map((card, index) => (
        <div key={index} className='card sliderCard column cardBlue white'>
          <div className='stars'>
            {Array.from({ length: card.stars }, (_, index) => (
              <span className='pr-1' key={index}>&#9733;</span>
            ))}
          </div>
          <div className='titleText'>{card.title}</div>
          <div className='medium fadedWhiteText sliderText middle top fadedTextContainer'>{card.text}</div>
          <div className='row'>
              <img src={card.profileIMG} alt='profile' className='profile' />
              <div className='column pl-1'>
                  <div className='medium'>{card.name}</div>
                  <div className='medium fadedWhiteText ptb-half'>{card.location}</div>
              </div>
          </div>
        </div>
      ))}
      </div>
      <div className='buttonRow row middle pt-1'>
        <FontAwesomeIcon icon={faAngleLeft} onClick={handlePrev} color='white' className='even40 pr-2'/>
        
        <FontAwesomeIcon icon={faAngleRight} onClick={handleNext}  color='white'  className='even40 pl-2'/>
        
      </div>
      <div className='mobile'>
      <Swiper
          spaceBetween={50}
          slidesPerView={1.5} 
          centeredSlides={true}
          initialSlide={2}
          centeredSlidesBounds={true} 
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper: SwiperClass) => console.log(swiper)}
        >
          {cardInfoL.map((card, index) => (
            <SwiperSlide key={index}>
              <div className='mobileCard card sliderCard column cardBlue white'>
                <div className='stars'>
                  {Array.from({ length: card.stars }, (_, index) => (
                    <span className='pr-1' key={index}>&#9733;</span>
                  ))}
                </div>
                <div className='titleText'>{card.title}</div>
                <div className='medium fadedWhiteText sliderText middle top fadedTextContainer'>{card.text}</div>
                <div className='row'>
                    <img src={card.profileIMG} alt='profile' className='profile' />
                    <div className='column pl-1'>
                        <div className='medium'>{card.name}</div>
                        <div className='medium fadedWhiteText ptb-half'>{card.location}</div>
                    </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        </div>
  </div>
);
};
