import './Header.scss';

import React, { useEffect, useState } from 'react';

import logo from '../ivyleaguelogo.png'
import logoWhite from '../ivywhite.png'
import { useNavigate } from 'react-router-dom';
import useScrollSpy from '../hooks/useScrollSpy';

type Link = {
        id: string;
        text: string;
    };
    
type HeaderProps = {
        links: Link[];
    };

export const Header: React.FC<HeaderProps> = ({ links }) => {
    const navigate = useNavigate();
    const activeSection = useScrollSpy(links.map(link => link.id));
    const [isPastHomePage, setIsPastHomePage] = useState(false);

  //   useEffect(() => {
  //     // handle scroll event
  //     const handleScroll = () => {
  //         const homePageHeight = 0.2 * window.innerHeight;
  //         setIsPastHomePage(window.scrollY > homePageHeight);
  //     };
  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

    useEffect(() => {
        if (activeSection) {
        navigate('/' + activeSection, { replace: true });
        }
    }, [activeSection, navigate]);

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // const headerClass = isPastHomePage ? 'header past-home' : 'header';

    return (
        <div className='header'>
          <div className='logo-container'>
            <img src={logoWhite} alt="ivy" className="icon"  onClick={() => scrollToSection('home')}/>
          </div>
          <div className='link-container'>
            {links.map(link => (
              <button key={link.id} onClick={() => scrollToSection(link.id)} className='white plr-1 ' >
                {link.text}
              </button>
            ))}
           </div>
    </div>
  );
};
