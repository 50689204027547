import './Contact.scss'

import { Form } from '../components/Form';
import React from 'react';
import icon from '../ivywhite.png'
import x1 from '../assests/16.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

export function Contact() {
    return (
        <div id="contact" className="contact white">
            <div className='titleText right'> Contact Us </div>
            <div className='medium right ptb-3'>Get in touch with us</div>
            <div className='desk row end2end'>
                <div className='column center halfW'>
                    <img src={icon} alt="ivy" className="icon mh-150 pb-1" />
                    <div className='middle'>
                        <div className=' row left pb-3'>
                            <FontAwesomeIcon icon={faEnvelope} />  <a href="mailto:ivyleaguepaint@gmail.com" className='pl-1'>ivyleaguepaint@gmail.com</a>
                            <FontAwesomeIcon icon={faPhone} className='pl-5' /> <a href="tel:+16043655225" className='pl-1'>+1 (604) 365-5225</a>

                        </div>
                    </div>
                    <img src={x1} alt="team" className=' br-25 contactPic' />
                   
                </div>
                <div className='halfW center'>
                    <Form />
                </div>
          
            </div>
            <div className='mobile center end2end pt-1'>
                    <Form />
                    <div className='middle'>
                        <div className=' row left pt-3'>
                            <FontAwesomeIcon icon={faEnvelope} />  <a href="mailto:ivyleaguepaint@gmail.com" className='pl-1'>ivyleaguepaint@gmail.com</a>
                        </div>
                        <div className=' row left pt-3'>
                            <FontAwesomeIcon icon={faPhone} className='pl-5' /> <a href="tel:+16043655225" className='pl-1'>+1 (604) 365-5225</a>
                        </div>
                    </div>
            </div>
        </div>
    );
}