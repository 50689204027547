import React, { useState } from "react";
import "./About.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faPause } from '@fortawesome/free-solid-svg-icons';

export function About() {
    const videoSource = [
        'https://www.youtube.com/embed/RFA4cV7LY0U?autoplay=1&controls=1&mute=1',
        'https://www.youtube.com/embed/Nyd9jtvJMWs?autoplay=1&controls=1&mute=1',
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleLeftClick = () => {
        setCurrentIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : videoSource.length - 1);
    };

    const handleRightClick = () => {
        setCurrentIndex(prevIndex => prevIndex < videoSource.length - 1 ? prevIndex + 1 : 0);
    };


    return (
        <div id="about" className="about">
            <div className="desk titleText right white"> Who We Are </div>
{/* desktop */}
            <div className='desk row center end2end ptb-3'>
                <div className="row center halfW">
                    <button className="button circle even50 clear fadedWhiteText" aria-label="Previous video" onClick={handleLeftClick}>
                        <FontAwesomeIcon icon={faAngleLeft} className='even20'/>
                    </button>
                    <div className="iframe-container">
                        <iframe
                            width="560"
                            height="315"
                            src={videoSource[currentIndex]}
                            title="YouTube video player"
                            frameBorder="0"
                            className='plr-1 br-25'
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                    <button className="button circle even50 clear fadedWhiteText" onClick={handleRightClick}>
                        <FontAwesomeIcon icon={faAngleRight} className='even20'/>
                    </button>
                </div>

                <div className="medium textCenter white top halfW">Ivy League Painting was created to help you keep your property looking it’s best! From humble beginnings, painting and power-washing the neighborhoods of Delta  quickly turned into servicing the vast lower mainland. Though we have grown and branched out, we continue to put our customer’s satisfaction at the forefront of our business. Rest easy knowing that we carry 5 MILLION in liability insurance and all of our painting work comes with a 3-year 100% satisfaction guarantee to reflect our confidence in our work!  </div>
            </div>

{/* mobile */}
        <div className="mobile titleText center white pb-3"> Who We Are </div>

        <div className='mobile plr-1'>
             <iframe
                width="100%"
                height="315"
                src={videoSource[currentIndex]}
                title="YouTube video player"
                frameBorder="0"
                className='br-25'
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
            ></iframe>
            <div className='row end2end'>
                    <button className="button circle even50 clear fadedWhiteText" aria-label="Previous video" onClick={handleLeftClick}>
                        <FontAwesomeIcon icon={faAngleLeft} className='even20'/>
                    </button>
                    {/* <button className="button circle even50 clear fadedWhiteText" aria-label="Pause Video" onClick={handlePauseVideo}>
                        <FontAwesomeIcon icon={faPause} />
                    </button> */}

                    <button className="button circle even50 clear fadedWhiteText" onClick={handleRightClick}>
                        <FontAwesomeIcon icon={faAngleRight} className='even20'/>
                    </button>
            </div>
        </div>
           
        </div>
        
    );
}
